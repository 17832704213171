function Footer() {


  return (
    <>
      <div className="footerStyle">
        <h3 className="headingStyle">What is Satta King?</h3>
        <p className="paragraphStyle">
          
            Satta King (सट्टा किंग) is a number lottery betting game. Satta
            Matka is an early form of gambling in India where the numbers
            involved in the game are from 00 to 99. In this game, people stake
            their money on any two-digit number for which the player gets 1000
            times the money they staked. Satta King is the term used to entitle
            the winner of the game Satta Matka. Over time, the game became
            popular with the term Satta King. Satta is a form of recreation
            performed between two or more people. It would help if you played
            Rummy a few times, but it isn't called Satta when you have played
            Rummy for entertainment. But if you play with cash in Rummy, it is
            referred to as betting, which is illegal in India. In simple
            language, playing any sport with cash is called Satta. Note: It is
            illegal to play betting in India. Through this blog, I want to offer
            you the correct information. We do not have any purpose in promoting
            betting. The data given here is for knowledge purposes only.
          
        </p>
      </div>
      <div className="footerStyle">
        <h3 className="headingStyle">History Of Satta King (Matka) Game</h3>
        <p className="paragraphStyle">
          
            The game initially started in Bombay ( Current Mumbai ) in the early
            1950s, where people initially used to place bets on the prices of
            cotton. Ten years later, this game was banned by the new york cotton
            exchange, and Kalyanji Bhagat thought of an alternative way to keep
            the Satta Matka business alive by drawing out numbers from a pot
            (Matka). At a later stage, Ratan Khatri adopted the same idea and
            used to conduct the Satta Matka game 6 days a week. The Satta Matka
            game started to gain popularity and frequently used the term Satta
            king to honor a person's win. In the late 1990s, the Matka business
            was at its peak, making a turnover of about Rs. 500 crores a month.
            Due to this, the government, in coordination with the Mumbai police,
            launched an operation to stop this game and eventually banned it.
            Currently, most of the Satta Matka game is played online.
          
        </p>
      </div>
      <div className="footerStyle">
        <h3 className="headingStyle">How to Play Satta King Online?</h3>
        <p className="paragraphStyle">
          
            In this sport, a slip from 1 to one a hundred numbers is put in a
            pot. The Lottery comes out at any person of those 100 numbers. Then
            the slip of one of the numbers is removed without seeing it. Then
            the variety that comes out, the person of that wide variety is said
            to be the winner of this sport. It is precisely like Lottery;
            however, its reward is in accordance with the number of people
            participating in this sport within the area. In many places, its
            reward is ₹ a thousand and somewhere around 1 lakh rupees, and in
            massive towns like Delhi, Mumbai, and Bangalore, those are making a
            bet up to at least one crore rupees. If the wide variety you leave
            is yours, then you are the winner of this game. In simple language,
            by playing this recreation, you can become a millionaire overnight,
            and you could turn out to be a roadmaster overnight. Therefore, if
            you follow our advice, you should now not play this sport in any
            respect because there is a chance in this game. Here may truly ruin
            you, and as far as we have seen, or we've heard, on this recreation,
            there may be the most direct 1% threat of winning; otherwise, 99%
            are defeated.
          
        </p>
      </div>
      <div className="footerStyle">
        <h3 className="headingStyle">What are Types of Satta King game?</h3>
        <p className="paragraphStyle">
          
            Many types of Satta king games are played worldwide, but the most
            notable ones are Gali, Desawar, Ghaziabad and Faridabad. Delaware is
            one of the most popular games run from Dubai. Other notable games
            include Mumbai Morning, Delhi king, Dubai King, etc. Check
            Sattakingy.in to get the list of games.
          
        </p>
      </div>
      <div className="footerStyle">
        <h3 className="headingStyle">Why do people play Satta King?</h3>
        <p className="paragraphStyle">
          
            The Very simple answer to why Satta King plays the sport is that
            when humans no longer want to work laborious in their existence, or
            they want to get extra cash or more progress over a short period. So
            they move in the direction of the speculator. Without difficult
            work, the person who desires to become wealthy runs toward betting
            or these wrong deeds. Therefore, this is the motive that humans have
            an ardor for playing Satta King or Satta. But allow us to tell you
            that all those works related to gambling are illegal in the eyes of
            the government if you are caught playing or making a bet anywhere in
            any public place. So you'll be punished severely, even if you may be
            imprisoned. We could like to inform you that without difficult work,
            no person has ever been given anything, nor will everybody ever get
            anything if you want to earn the correct cash in your existence or
            want to get wealthy. So you must work hard and believe in your hard
            work. You may only reap a better position in your life or become
            wealthy. Lazy people do not work challenging. And go through these,
            making a betting cycle. They also inspire and inform other humans,
            which you ought to bet. But playing a bet isn't the right way to
            earn money. People who play betting assume that when the lottery
            starts, they will do it, I will do it, I will turn rich; they keep
            considering all these useless questions because they could never
            work hard or earn good money. We advise you to avoid making a bet;
            otherwise, you can lose all your money. You can harm yourself and
            your family.
          
        </p>
      </div>
      <div className="footerStyle">
        <h3 className="headingStyle">What happens when people play Satta King?</h3>
        <p className="paragraphStyle">
          
            Most humans think that they will win if they play betting, but it's
            far reversed. , He loses on this recreation in this sort of horrific
            way, that he loses everything and is ruined. Because in this sport,
            most superficial, one person out of 100 takes the lottery. The crew
            of Satta King also chooses the same quantity if you want to have
            less cash because if that range comes out, then the Satta King team
            will benefit more. So the relaxation of the 99 folks that survived
            is the only losers in this recreation. He wastes each his cash and
            his time gambling on this sport. Because of this, they neither do
            any work nor are they capable of earning money. Those who play
            betting handiest consider betting that will do my lottery, what
            number might be there tomorrow, which range is today, and what
            number can or not it's the day after tomorrow. Only these questions
            hold circulating in his mind. And after a few weeks or some months
            or some years, they are ruined. If you are also in that situation,
            in that case, you begin betting. So after some time, your scenario
            will get worse. You could be absolutely pauper. Therefore, the
            Government of India wants to ban such sports activities for the
            betterment of humans, because of which human beings can do well.
          
        </p>
      </div>
      <div className="footerStyle">
        <h3 className="headingStyle">The Reality of Satta King Game?</h3>
        <p className="paragraphStyle">
          
            As we advised you above, Satta King is a lottery game. In which one
            hundred humans take part on their loose will. One man or woman is
            chosen because the winner is between 1 to a hundred numbers. And for
            this, all the one hundred numbers are put in a pot. Then a slip of
            any quantity is drawn. The winner, who will have a wide variety on
            that slip, will come. Meaning it's far a recreation of luck. But
            wait, the truth of this sport is something else. That is how I
            described this sport. There is nothing of that type in this
            recreation. Because, in fact, inside the Satta King, he does now not
            choose the slip of any one character using setting the number inside
            the pot; however, most superficial, the wide variety, which has much
            less cash out of 100 quantity, is asserted the winner. In this
            method, the group of Satta King receives several profits, which
            means that who is the winner or not depends on the team Satta King.
            Many humans in India also realize that Satta King is dishonest in
            sports. But he still stays unaware because he thinks that the Satta
            King should now not have this sort of quantity, that the variety is
            eliminated which can be his variety, and he can win. However, it's
            miles impossible. Told you above Satta King is a lottery game. In
            which one hundred people take part on their unfastened will. One
            character is chosen because the winner is among 1 one to hundred
            numbers. And for this, all the 100 numbers are put in a pot. Then a
            slip of any range is drawn. The winner, who could have the range on
            that slip, will come. Meaning it's far a sport of luck. But wait,
            the truth of this recreation is something else. That is how I
            described this recreation. There is not anything of that type in
            this game. Because, in fact, inside the Satta King, he does now not
            pick the slip of any one character using setting the number in the
            pot, but most effective, the range which has much less cash out of
            one hundred range, is declared the winner. The method of the group
            Satta King gets loads of profit, which means who's the winner no
            longer depends on the crew of Satta King. Many people in India also
            recognize that Satta King is dishonest in sports. But he still stays
            clueless, as he thinks that the Satta King should now not have the
            sort of wide variety, that the number be eliminated, which can be
            his number, and he can win. However, it's miles impossible.
          
        </p>
      </div>
      <div className="footerStyle">
        <h3 className="headingStyle">is Satta King Leagal in India?</h3>
        <p className="paragraphStyle">
          
            Thank you for A2A. Satta, Matka, Tin Patti, poker, blackjack etc.,
            if played, the use of money becomes playing & hence unlawful.
            Gambling has been illegal in India for, without a doubt, a long term
            now, considering the British raj. Various legal guidelines ban
            playing in all forms; however, somehow, wagering on horse races
            isn't always considered playing, as it is only played by means of
            the rich. Recently, Madras High Court decided on an exciting case
            known as Mahalaxmi Cultural Association Vs. IGP. The court docket,
            in clear terms, held what I had said above. In any case, as far as
            Maharashtra is concerned, the 1957 judgment of the Supreme Court,
            i.E. State of Bombay v. RMD.Chamarbaugwala, AIR 1957 SC 699, will
            apply.
          
        </p>
      </div>
      <div className="footerStyle">
        <h3 className="headingStyle">Satta King Leak Number</h3>
        <p className="paragraphStyle">
          
            Play Satta King with 100% Direct Speculative Leak in the Gali and
            Disawar and earn lakhs of rupees; we will give you a leak, and the
            result will be in front of you. Our company is only with those
            people who are upset and have incurred heavy losses. You will have
            to deposit fees in a Direct Company Account, Not only in the
            personnel of the company's work. Gali and Desawar will meet Leek
            Jodi, who will make you a wealthy person. It is the only road that
            can change your fate. In today's time, the lost player or loan will
            come to us. We will free you from debt and make you wealthy; you
            will also get millions of crores; the leaked Jodi will always be
            with you. Check our Lucky satta page Today!
          
        </p>
      </div>
      <div className="footerStyle">
        <h3 style={{ color: "rgb(255, 0, 0)", fontSize: "x-large" }}>
          चेतावनी (WARNING)
        </h3>

        <p className="warningStyle">
          यह साइट सिर्फ और सिर्फ मनोरंजन के लिए बनाई गई है । यह साइट सट्टे से
          जुड़ी किसी भी गतिविधि को बढ़ावा नहीं देती । सट्टा और जुआं जिस देश या
          राज्य में प्रतिबंधित है वहां के लोग हमारी साइट को ब्लॉक कर दें | किसी
          भी लाभ या हानि के लिए आप खुद जिम्मदार होंगे ।
        </p>
      </div>
      <div className="footerStyle">
        <h3 style={{ color: "rgb(255, 0, 0)", fontSize: "x-large" }}>
          SATTA KING DISCLAIMER
        </h3>

        <p className="warningStyle">
          Dear user, We would like to inform you that we and our site
          (SATTAKINGY.IN) are not involved with any online gambling activity.
          all the data on the website are just records and are sponsored. We do
          not want to harm the rules and regulations of any country. We also
          want to inform you that playing the Satta king game may not be legal
          in your country, so it's better to quit today. We are not responsible
          for any profit and loss you have made using the result of the site.
          Thanks
        </p>
      </div>
    </>
  );
}

export default Footer;
