import "./App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHome } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import trackVisitor from "./utilities/tracker";
import GameResult from "./js/GameResult";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";

// const momenttz = require("moment-timezone");
function App() {
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  const currentTime = moment().format("HH:mm");
  const location = useLocation();
  const isDisclaimer = location.pathname.includes("/disclaimer");
  const isAbout = location.pathname.includes("/about-us");
  const isPrivacy = location.pathname.includes("/privacy-policy");

  const todayDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");
  var prevDate = moment(new Date())
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  // const [gamedata, setGameData] = useState([]);
  useEffect(() => {
    trackVisitor();
  }, []);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      // const currentMoment = moment(currentTime, "HH:mm");

      // Process and filter the data
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(
        (item) => item.isAvailable
      );
      const upcomingRecords = sortedProcessedData.filter(
        (item) => !item.isAvailable
      );

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        // Show available results and include records up to the next upcoming record
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        // No available results, show up to 3 upcoming records with "wait"
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }

      // Ensure only 3 records are shown
      if (recordsToDisplay.length > 3) {
        // Remove the oldest record if more than 3 records are present
        recordsToDisplay = recordsToDisplay.slice(-3);
      }

      // Update state with the processed and limited data
      setDataFor(recordsToDisplay);

      // Debugging log
    }
  }, [data, currentTime]);

  const pageScroll = () => {
    const tableSection = document.getElementById("table-section");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="app">
      {/* seo setup start */}
      <Helmet>
        <title></title>
        <meta name="description" content="sattakingy, satta kingy, sattaking" />
        <meta name="Keywords"
          content="sattakingy, satta kingy, sattaking, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king," />
        <link rel="canonical" href="https://sattakingy.co/" />
      </Helmet>
      {/* seo setup end */}
      <div className="col-md-12 menu text-center">
        <div className="row">
          <div className="col-md-4 col-sm-12 nav-link">
            <a className="text-black" href="/">
              HOME
            </a>
          </div>
          <div className="col-md-4 col-sm-12 nav-link">
            {/* <a className="text-white" href="#" >
              SATTA CHART
            </a> */}
            <a
              onClick={(e) => {
                e.preventDefault();
                pageScroll();
              }}
              href="chart"
              className="text-black"
            >
              CHART
            </a>
          </div>
          <div className="col-md-4 col-sm-12 nav-link">
            <a
              onClick={(e) => {
                e.preventDefault();
                pageScroll();
              }}
              href="SATTA KING 2024"
              className="text-black"
            >
              SATTA KING 2024
            </a>
          </div>
        </div>
      </div>

      {!(isAbout || isDisclaimer || isPrivacy) && (
        <>
          {/* <marquee className="border">
            SATTA KING, SATTAKING, SATTA RESULT, GALI RESULT, GALI SATTA, SATTA
            BAZAR, GALI SATTA RESULT, SATTA KING 2024, SATTA KING RESULT, SATTA
            KING UP, SATTA GAME TODAY RESULT, SATTA RESULT CHART, SATTA KING
            LIVE, DESAWAR SATTA, FARIDABAD SATTA, FARIDABAD RESULT, BLACK SATTA
            KING
          </marquee> */}

          <div className="text-center highlight p-1 bg-black">
            <h3 className="fw-bold text-danger">SATTA KING</h3>
          </div>

          <div className="text-center mt-4">
            <div className="text-center p-1 bg-primary fw-bolder text-white">
              <h2>SATTAKINGY.CO BEST SITE SATTA RESULT</h2>
              <p>SATTAKINGY.CO</p>
            </div>

            <div className="text-center">
              <p className="text-danger fw-bold fs-3 mb-0">{todayDate}</p>
              <p className="text-dark fw-bold fs-5 mb-0">
                Satta King Live Result Today
              </p>

              {/* {gamedata.map((game, index) =>
                game.game_name == "MORNING STAR" ||
                game.game_name == "SILVER CITY" ||
                game.game_name == "HR 20" ? (
                  <div key={index}>
                    <p className="text-danger fw-bold mb-0 fs-2">
                      {game.game_name}
                    </p>
                    <p className="text-success fw-bold mb-0 fs-2">
                      {game?.curr_date?.result || "--"}
                    </p>
                  </div>
                ) : (
                  ""
                )
              )} */}

              {datagame?.map((todayData, index) => (
                <div key={index}>
                  <p className="game-name mb-0">{todayData?.gameName}</p>
                  <p className="game-result lh-1 blinking-text">
                    {todayData?.result || "wait"}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <AdvertisementComponent type="odd" />
      <GameResult dayGameData={data} />
    </div>
  );
}

export default App;
